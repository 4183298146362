import type { GADataProps } from "@elements/GAData/GAData";
import type { MetaTagsProps } from "@elements/MetaTags/MetaTags";
import type { PageMetaDataValues } from "@typings/PageMetaData";

export default function GeneralElectionsMetaData(
  section: string,
): PageMetaDataValues {
  const metaTags: MetaTagsProps = {
    title:
      "GE2025: Berita & Perkembangan Terkini Pilihan Raya Umum Singapura | Beritaharian.sg",
    description:
      "Dapatkan berita, video, dan analisis mendalam terkini mengenai Pilihan Raya Umum Singapura GE2025. Ikuti perkembangan terbaru secara langsung dari Beritaharian.sg.",
    slug: section,
    keywords: [
      "GE2025",
      "Pilihan Raya Umum Singapura",
      "Pilihan Raya Singapura 2025",
      "Berita GE2025",
      "Perkembangan Terkini GE2025",
      "Politik Singapura",
      "Keputusan Pilihan Raya Singapura",
      "Berita Beritaharian",
      "Analisis Pilihan Raya Singapura",
      "Undi Singapura 2025",
    ],
  };

  const gaData: GADataProps = {
    level2: section,
    title: "Pilihan_Raya_Umum_Singapura_2025_Index",
    section: section,
    contentcat: "1",
    contenttype: "listing",
  };

  return {
    gaData,
    metaTags,
  };
}
