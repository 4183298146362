import GEIcon from "@assets/ge2025/ge-title-icon.svg";
import Heading from "@elements/Typography/Heading";
import BHBasePage, { type BHBasePageProps } from "@pages/BHBasePage";
import ErrorPage from "@pages/ErrorPage";
import WidgetWrapper from "@pages/Home/HomePageLayout/WidgetWrapper";
import NotFound from "@pages/NotFound";
import type { TRouteWithoutRedirect } from "@sphtech/web2-core/ssr";
import {
  CustomContext,
  ResponseType,
  useRouteContext,
} from "@sphtech/web2-core/ssr";
import { PageAdTargetingTypeEnum } from "@typings/Ads.d";

import GEKeywordArticlesList from "./Components/GEKeywordArticleList";
import GELiveBlog from "./Components/GELiveBlog";
import GEMapWidget from "./Components/GEMapWidget";
import GESTWidget from "./Components/GESTWidget";
import GETopStoryWidget from "./Components/GETopStoryWidget";
import { GEPayloadResponseType } from "./GeneralElections.server";
import GeneralElectionsMetaData from "./GeneralElectionsMetaData";

export function GeneralElections() {
  const routeContext: CustomContext<
    TRouteWithoutRedirect<GEPayloadResponseType, string>
  > = useRouteContext();
  const dataLoaderResponse = routeContext.context;

  if (dataLoaderResponse.type === ResponseType.CLIENT_ERROR) {
    return <NotFound />;
  } else if (dataLoaderResponse.type === ResponseType.SERVER_ERROR) {
    return <ErrorPage message="Something went wrong" />;
  }

  const {
    topStories,
    tagsData,
    excludeIds,
    geHeadline,
    geRecordedVideo,
    geSTWidget,
    geGovTechWidget,
    geLiveBlog,
  } = dataLoaderResponse.payload;

  const adsSectionName = "ge2025-pilihan-raya-umum";
  const { gaData, metaTags } = GeneralElectionsMetaData(adsSectionName);
  const pageAdTargetValue = PageAdTargetingTypeEnum.LISTING;

  const basePageProps: BHBasePageProps = {
    uniqueSectionName: adsSectionName,
    gaData: gaData,
    metaTags: metaTags,
    pageAdTargetValue: pageAdTargetValue,
    hasLb1Ads: true,
    showGEBanner: false,
    bodyWrapperStyle: "bg-white-200 my-0 lg:my-0 py-3 lg:py-md",
  };

  return (
    <BHBasePage {...basePageProps}>
      <WidgetWrapper>
        <div>
          <div className="border-b-[1px] border-grey-400 pb-[10px] md:pb-[20px] mb-[10px] md:mb-[20px]">
            <div className="gap-xs hidden md:flex">
              <img src={GEIcon} alt="ge-icon" width={77} height={77} />
              <Heading className="text-red-100 text-[30px] font-semibold font-secondary ">
                Pilihan Raya Umum
                <br />
                Singapura 2025
              </Heading>
            </div>
            <div className="flex gap-xs md:hidden">
              <img src={GEIcon} alt="ge-icon" width={30} height={30} />
              <Heading className="text-red-100 text-[18px] font-semibold font-secondary">
                Pilihan Raya Umum Singapura 2025
              </Heading>
            </div>
          </div>
          <div dangerouslySetInnerHTML={{ __html: geHeadline }} />
        </div>
        <div className="flex gap-[40px]">
          <div className="w-full lg:w-[992px] xl:w-[847px] flex flex-col gap-5">
            {geSTWidget && <GESTWidget geSTWidgetContent={geSTWidget} />}
            <div dangerouslySetInnerHTML={{ __html: geRecordedVideo }} />
            <GETopStoryWidget articles={topStories} />
            {geLiveBlog && (
              <div className="xl:hidden">
                <GELiveBlog variant="body" geLiveBlogContent={geLiveBlog} />
              </div>
            )}
            {geGovTechWidget && (
              <GEMapWidget geGovTechContent={geGovTechWidget} />
            )}
            <GEKeywordArticlesList
              articles={tagsData}
              excludeIds={excludeIds}
              adsSectionName={adsSectionName}
            />
          </div>
          {geLiveBlog && (
            <div className="hidden xl:block">
              <GELiveBlog variant="sidebar" geLiveBlogContent={geLiveBlog} />
            </div>
          )}
        </div>
      </WidgetWrapper>
    </BHBasePage>
  );
}
