import { BH_BASE_URL } from "@helper/getEnvVariables";
import { LatestVideoResponse } from "@hooks/transformer/useBrightcoveResponse";
import { ReactElement } from "react";

import { generateUTMUrl } from "../Util/generateUtmUrl";

type NewsletterVideosProps = {
  videos: LatestVideoResponse[];
};

const images = {
  video: `${BH_BASE_URL}assets/newsletter/video-title.png`,
};

/**
 * NewsletterVideo
 * Description: [Newsletter videos section]
 * @param props - The props required by the component (if any)
 * @returns The rendered React element
 */
export default function NewsletterVideos({
  videos,
}: NewsletterVideosProps): ReactElement {
  if (!Array.isArray(videos)) {
    return <></>;
  }

  const hasVideoFirstRow = videos.length > 1;
  const hasVideoSecondRow = videos.length > 3;

  return (
    <table width="100%" cellSpacing={0} cellPadding={0} border={0}>
      <tbody>
        <tr>
          <td
            style={{
              WebkitTextSizeAdjust: "100%",
              margin: 0,
              padding: "10px 0",
            }}
          >
            <table
              role="presentation"
              cellPadding="0"
              cellSpacing="0"
              border={0}
            >
              <tbody>
                <tr>
                  <td style={{ verticalAlign: "middle", paddingRight: "5px" }}>
                    <img
                      src={images.video}
                      alt="Video Title"
                      style={{
                        border: 0,
                        height: "auto",
                        lineHeight: "100%",
                        outline: "none",
                        textDecoration: "none",
                        verticalAlign: "middle",
                        display: "block",
                      }}
                    />
                  </td>
                  <td style={{ verticalAlign: "middle" }}>
                    <h4
                      style={{
                        padding: 0,
                        margin: 0,
                        wordBreak: "normal",
                        fontFamily: "Lato",
                        fontStyle: "normal",
                        fontWeight: "bold",
                        fontSize: "23px",
                        lineHeight: "135%",
                        color: "#005aab",
                      }}
                    >
                      Chill & Tonton
                    </h4>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
        {hasVideoFirstRow && (
          <tr>
            {[0, 1].map(
              (idx) =>
                videos[idx] && (
                  <td
                    key={idx}
                    style={{
                      width: "50%",
                      ...(idx % 2 == 0
                        ? { paddingRight: "15px" }
                        : { paddingLeft: "15px" }),
                      paddingBottom: "10px",
                      paddingTop: "10px",
                      verticalAlign: "top",
                    }}
                  >
                    <a
                      href={generateUTMUrl(
                        `/videos/${videos[idx].id}`,
                        videos[idx].name,
                      )}
                      target="_blank"
                      style={{
                        color: "#005aab",
                        textDecoration: "underline",
                      }}
                      rel="noreferrer"
                    >
                      <img
                        src={videos[idx].thumbnail}
                        alt={videos[idx].name}
                        style={{
                          width: "100%",
                          height: "auto",
                          display: "block",
                        }}
                      />
                      <div
                        style={{
                          fontSize: "12px",
                          lineHeight: "140%",
                          color: "#ffffff",
                          background: "rgba(0, 0, 0, 0.6)",
                          padding: "4px",
                          textAlign: "right",
                          marginBottom: "20px",
                        }}
                      >
                        {videos[idx].formattedTime}
                      </div>
                      <h6
                        style={{
                          textAlign: "left",
                          fontSize: "18px",
                          fontWeight: "bold",
                          color: "#005aab",
                          margin: "10px 0",
                        }}
                      >
                        {videos[idx].name}
                      </h6>
                    </a>
                  </td>
                ),
            )}
          </tr>
        )}
        {hasVideoSecondRow && (
          <tr>
            {[2, 3].map(
              (idx) =>
                videos[idx] && (
                  <td
                    key={idx}
                    style={{
                      width: "50%",
                      ...(idx % 2 == 0
                        ? { paddingRight: "15px" }
                        : { paddingLeft: "15px" }),
                      paddingBottom: "10px",
                      paddingTop: "10px",
                      verticalAlign: "top",
                    }}
                  >
                    <a
                      href={generateUTMUrl(
                        `/videos/${videos[idx].id}`,
                        videos[idx].name,
                      )}
                      target="_blank"
                      style={{
                        color: "#005aab",
                        textDecoration: "underline",
                      }}
                      rel="noreferrer"
                    >
                      <img
                        src={videos[idx].thumbnail}
                        alt={videos[idx].name}
                        style={{
                          width: "100%",
                          height: "auto",
                          display: "block",
                        }}
                      />
                      <div
                        style={{
                          fontSize: "12px",
                          lineHeight: "140%",
                          color: "#ffffff",
                          background: "rgba(0, 0, 0, 0.6)",
                          padding: "4px",
                          textAlign: "right",
                          marginBottom: "20px",
                        }}
                      >
                        {videos[idx].formattedTime}
                      </div>
                      <h6
                        style={{
                          textAlign: "left",
                          fontSize: "18px",
                          fontWeight: "bold",
                          color: "#005aab",
                          margin: "10px 0",
                        }}
                      >
                        {videos[idx].name}
                      </h6>
                    </a>
                  </td>
                ),
            )}
          </tr>
        )}
        <tr style={{ borderBottom: "1px solid #e4e4e4" }}>
          <td
            colSpan={2}
            style={{
              textAlign: "right",
              paddingBottom: "10px",
            }}
          >
            <a
              href={`${BH_BASE_URL}videos`}
              target="_blank"
              style={{
                fontFamily: "Lato",
                fontSize: "16px",
                lineHeight: "135%",
                color: "#828282",
              }}
              rel="noreferrer"
            >
              Laporan lain &gt;
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  );
}
