import { BH_BASE_URL } from "@helper/getEnvVariables";
import { ProcessedArticleData } from "@hooks/transformer/useOSResponse";
import { ReactElement } from "react";

import { generateUTMUrl } from "../Util/generateUtmUrl";

type NewsletterTopStoriesProps = {
  topStories: ProcessedArticleData[];
};

const images = {
  topStoryIcon: `${BH_BASE_URL}assets/newsletter/top-story-title.png`,
  defaultImg: `${BH_BASE_URL}assets/newsletter/BH_placeholder.jpg`,
};

/**
 * TopStories
 * Description: [Newsletter preview top stories section]
 * @param props - The props required by the component (if any)
 * @returns The rendered React element
 */
export default function NewsletterTopStories({
  topStories,
}: NewsletterTopStoriesProps): ReactElement {
  if (!Array.isArray(topStories)) {
    return <></>;
  }

  const firstStory = topStories.length >= 1 && topStories[0];
  const hasFirstRow = topStories.length > 2;
  const hasSecondRow = topStories.length > 4;

  return (
    <table width="100%" cellSpacing={0} cellPadding={0} border={0}>
      <tbody>
        {firstStory && (
          <>
            <tr>
              <td
                style={{
                  WebkitTextSizeAdjust: "100%",
                  margin: 0,
                  padding: "10px 0",
                }}
              >
                <table
                  role="presentation"
                  cellPadding="0"
                  cellSpacing="0"
                  border={0}
                >
                  <tbody>
                    <tr>
                      <td
                        style={{ verticalAlign: "middle", paddingRight: "5px" }}
                      >
                        <img
                          src={images.topStoryIcon}
                          alt="Top Story Title"
                          style={{
                            border: 0,
                            height: "auto",
                            lineHeight: "100%",
                            outline: "none",
                            textDecoration: "none",
                            verticalAlign: "middle",
                            display: "block",
                          }}
                        />
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        <h4
                          style={{
                            padding: 0,
                            margin: 0,
                            wordBreak: "normal",
                            fontFamily: "Lato",
                            fontStyle: "normal",
                            fontWeight: "bold",
                            fontSize: "23px",
                            lineHeight: "135%",
                            color: "#005aab",
                          }}
                        >
                          Berita Anda Perlu Tahu
                        </h4>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr style={{ borderBottom: "1px solid #e4e4e4" }}>
              <td
                colSpan={2}
                style={{ textAlign: "left", paddingBottom: "10px" }}
              >
                <a
                  href={generateUTMUrl(firstStory.urlPath, firstStory.title)}
                  target="_blank"
                  style={{ color: "#005aab", textDecoration: "underline" }}
                  rel="noreferrer"
                >
                  <img
                    src={firstStory.imageField?.url || images.defaultImg}
                    alt={firstStory.imageAlt}
                    style={{
                      width: "100%",
                      maxWidth: "750px",
                      height: "auto",
                      display: "block",
                    }}
                  />
                  <h5
                    id="newsletter-subject"
                    style={{
                      fontSize: "28px",
                      fontWeight: "bold",
                      color: "#005aab",
                      margin: "10px 0",
                    }}
                  >
                    {firstStory.title}
                  </h5>
                </a>
                <p
                  style={{
                    fontFamily: "Lato",
                    fontSize: "14px",
                    lineHeight: "140%",
                    color: "#4f4f4f",
                    margin: "10px 0",
                    textAlign: "left",
                  }}
                >
                  {firstStory.paragraph}
                </p>
              </td>
            </tr>
          </>
        )}
        {hasFirstRow && (
          <tr>
            {[1, 2].map(
              (idx) =>
                topStories[idx] && (
                  <td
                    key={idx}
                    style={{
                      width: "50%",
                      ...(idx % 2 == 1
                        ? { paddingRight: "15px" }
                        : { paddingLeft: "15px" }),
                      paddingBottom: "10px",
                      paddingTop: "10px",
                      verticalAlign: "top",
                    }}
                  >
                    <a
                      href={generateUTMUrl(
                        topStories[idx].urlPath,
                        topStories[idx].title,
                      )}
                      target="_blank"
                      style={{
                        color: "#005aab",
                        textDecoration: "underline",
                      }}
                      rel="noreferrer"
                    >
                      <img
                        src={
                          topStories[idx].imageField?.url || images.defaultImg
                        }
                        alt={topStories[idx].imageAlt}
                        style={{
                          width: "100%",
                          height: "auto",
                          display: "block",
                        }}
                      />
                      <h6
                        style={{
                          textAlign: "left",
                          fontSize: "18px",
                          fontWeight: "bold",
                          color: "#005aab",
                          margin: "10px 0",
                        }}
                      >
                        {topStories[idx].title}
                      </h6>
                    </a>
                  </td>
                ),
            )}
          </tr>
        )}
        {hasSecondRow && (
          <tr>
            {[3, 4].map(
              (idx) =>
                topStories[idx] && (
                  <td
                    key={idx}
                    style={{
                      width: "50%",
                      ...(idx % 2 == 1
                        ? { paddingRight: "15px" }
                        : { paddingLeft: "15px" }),
                      paddingBottom: "10px",
                      verticalAlign: "top",
                    }}
                  >
                    <a
                      href={generateUTMUrl(
                        topStories[idx].urlPath,
                        topStories[idx].title,
                      )}
                      target="_blank"
                      style={{
                        color: "#005aab",
                        textDecoration: "underline",
                      }}
                      rel="noreferrer"
                    >
                      <img
                        src={
                          topStories[idx].imageField?.url || images.defaultImg
                        }
                        alt={topStories[idx].imageAlt}
                        style={{
                          width: "100%",
                          height: "auto",
                          display: "block",
                        }}
                      />
                      <h6
                        style={{
                          textAlign: "left",
                          fontSize: "18px",
                          fontWeight: "bold",
                          color: "#005aab",
                          margin: "10px 0",
                        }}
                      >
                        {topStories[idx].title}
                      </h6>
                    </a>
                  </td>
                ),
            )}
          </tr>
        )}
      </tbody>
    </table>
  );
}
