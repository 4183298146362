import { BH_BASE_URL } from "@helper/getEnvVariables";
import { ProcessedArticleData } from "@hooks/transformer/useOSResponse";
import { ReactElement } from "react";

import { generateUTMUrl } from "../Util/generateUtmUrl";

type NewsletterGayaHidupStoriesProps = {
  gayaHidupStories: ProcessedArticleData[];
};

const images = {
  gayaHidupIcon: `${BH_BASE_URL}assets/newsletter/gaya-story-title.png`,
  defaultImg: `${BH_BASE_URL}assets/newsletter/BH_placeholder.jpg`,
};

/**
 * NewsletterGayaHidupStories
 * Description: [Gaya Hidup/Santai section]
 * @param props - The props required by the component (if any)
 * @returns The rendered React element
 */
export default function NewsletterGayaHidupStories({
  gayaHidupStories,
}: NewsletterGayaHidupStoriesProps): ReactElement {
  return (
    <table width="100%" cellSpacing={0} cellPadding={0} border={0}>
      <tbody>
        {Array.isArray(gayaHidupStories) && (
          <>
            <tr style={{ borderTop: "1px solid #e4e4e4" }}>
              <td
                style={{
                  WebkitTextSizeAdjust: "100%",
                  margin: 0,
                  padding: "10px 0",
                }}
              >
                <table
                  role="presentation"
                  cellPadding="0"
                  cellSpacing="0"
                  border={0}
                >
                  <tbody>
                    <tr>
                      <td
                        style={{ verticalAlign: "middle", paddingRight: "5px" }}
                      >
                        <img
                          src={images.gayaHidupIcon}
                          alt="Gaya Hidup Story Title"
                          style={{
                            border: 0,
                            height: "auto",
                            lineHeight: "100%",
                            outline: "none",
                            textDecoration: "none",
                            verticalAlign: "middle",
                            display: "block",
                          }}
                        />
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        <h4
                          style={{
                            padding: 0,
                            margin: 0,
                            wordBreak: "normal",
                            fontFamily: "Lato",
                            fontStyle: "normal",
                            fontWeight: "bold",
                            fontSize: "23px",
                            lineHeight: "135%",
                            color: "#005aab",
                          }}
                        >
                          Santai
                        </h4>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <table style={{ width: "100%" }}>
                  <tbody>
                    {gayaHidupStories.map((story, idx) => (
                      <tr key={idx}>
                        <td style={{ width: "30%", paddingRight: "10px" }}>
                          <a
                            href={generateUTMUrl(story.urlPath, story.title)}
                            target="_blank"
                            style={{
                              color: "#005aab",
                              textDecoration: "none",
                            }}
                            rel="noreferrer"
                          >
                            <img
                              src={story.imageField?.url || images.defaultImg}
                              alt={story.imageAlt}
                              style={{
                                width: "100%",
                                height: "140px",
                                display: "block",
                                marginBottom: "20px",
                              }}
                            />
                          </a>
                        </td>
                        <td style={{ width: "70%", verticalAlign: "top" }}>
                          <a
                            href={generateUTMUrl(story.urlPath, story.title)}
                            target="_blank"
                            style={{
                              color: "#005aab",
                              textDecoration: "underline",
                            }}
                            rel="noreferrer"
                          >
                            <h6
                              style={{
                                textAlign: "left",
                                fontSize: "18px",
                                fontWeight: "bold",
                                color: "#005aab",
                                margin: "0",
                              }}
                            >
                              {story.title}
                            </h6>
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </td>
            </tr>
            <tr style={{ borderBottom: "1px solid #e4e4e4" }}>
              <td
                colSpan={2}
                style={{
                  textAlign: "right",
                  paddingBottom: "10px",
                }}
              >
                <a
                  href={`${BH_BASE_URL}gaya-hidup`}
                  target="_blank"
                  style={{
                    fontFamily: "Lato",
                    fontSize: "16px",
                    lineHeight: "135%",
                    color: "#828282",
                  }}
                  rel="noreferrer"
                >
                  Laporan lain &gt;
                </a>
              </td>
            </tr>
          </>
        )}
      </tbody>
    </table>
  );
}
