import { BH_BASE_URL } from "@helper/getEnvVariables";
import useRemoveOverlay from "@hooks/useRemoveOverlay";
import ErrorPage from "@pages/ErrorPage";
import NotFound from "@pages/NotFound";
import type { TRouteWithoutRedirect } from "@sphtech/web2-core/ssr";
import { CustomContext, useRouteContext } from "@sphtech/web2-core/ssr";
import { ResponseType } from "@sphtech/web2-core/ssr";

import NewsletterGayaHidupStories from "./Components/NewsletterGayaHidupStories";
import NewsletterHeader from "./Components/NewsletterHeader";
import NewsletterPodcasts from "./Components/NewsletterPodcasts";
import NewsletterTopStories from "./Components/NewsletterTopStories";
import NewsletterVideos from "./Components/NewsletterVideos";
import { PayloadResponseType } from "./Newsletter.server";

export function NewsletterPreview() {
  const routeContext: CustomContext<
    TRouteWithoutRedirect<PayloadResponseType, string>
  > = useRouteContext();
  useRemoveOverlay();
  const dataLoaderResponse = routeContext.context;

  if (dataLoaderResponse.type === ResponseType.CLIENT_ERROR) {
    return <NotFound />;
  } else if (dataLoaderResponse.type === ResponseType.SERVER_ERROR) {
    return <ErrorPage message="Something went wrong" />;
  }

  const { storyQueue, gayaHidupStories, ugcBanner, videos, podcasts } =
    dataLoaderResponse.payload;

  return (
    <div style={{ width: "100%", textAlign: "center" }}>
      <table
        style={{
          width: "100%",
          maxWidth: "750px",
          margin: "0 auto",
          borderCollapse: "collapse",
        }}
      >
        <tbody>
          <tr>
            <td colSpan={2} style={{ paddingBottom: "10px" }}>
              <NewsletterHeader />
            </td>
          </tr>
          <tr>
            <td colSpan={2} style={{ paddingBottom: "10px" }}>
              <div dangerouslySetInnerHTML={{ __html: ugcBanner }} />
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <NewsletterTopStories topStories={storyQueue} />
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <NewsletterGayaHidupStories gayaHidupStories={gayaHidupStories} />
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <NewsletterVideos videos={videos} />
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <NewsletterPodcasts podcasts={podcasts} />
            </td>
          </tr>
          <tr>
            <td
              colSpan={2}
              align="center"
              style={{
                paddingTop: "40px",
              }}
            >
              <div
                style={{
                  border: "1px solid #d9d9d9",
                  background: "#ECF8FF",
                  borderColor: "#b9e5ff",
                  padding: "18px",
                  fontFamily: "IBM Plex Serif",
                  fontWeight: "bold",
                  color: "#181818",
                }}
              >
                <strong>
                  <a
                    href="https://subscribe.sph.com.sg/publications-bh/?utm_campaign=bh_subscription&utm_medium=sph-publication&utm_source=bh&utm_content=subscribelink-footer"
                    className="subscription"
                    style={{
                      fontSize: "18px",
                      lineHeight: "23px",
                      textAlign: "center",
                      color: "#224866",
                      textDecoration: "underline",
                    }}
                  >
                    Langgani BeritaHarian.sg Sekarang
                  </a>
                </strong>
              </div>
            </td>
          </tr>
          <tr>
            <td
              colSpan={2}
              align="center"
              className="unsubscribe"
              style={{
                paddingBottom: "20px",
              }}
            >
              <div
                style={{
                  background: "linear-gradient(0deg, #447AB2, #447AB2)",
                  padding: "8px",
                  fontFamily: "Lato, sans-serif",
                  fontSize: "12px",
                  lineHeight: "155%",
                  textAlign: "center",
                  color: "#ffffff",
                }}
              >
                If you want to unsubscribe from our mailing list, unsubscribe{" "}
                <a
                  href={`${BH_BASE_URL}bh_newsletter/unsubscribe`}
                  style={{
                    color: "#fff",
                    textDecoration: "underline",
                  }}
                >
                  here.
                </a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
