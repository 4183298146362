/**
 * Company: SPHMedia
 * Description: [GE Live blog]
 */
import { ReactElement } from "react";

type GELiveBlogStyleProps = {
  wrapperStyle?: string;
};

type GELiveBlogProps = {
  variant: "sidebar" | "body";
  geLiveBlogContent: string;
  styles?: GELiveBlogStyleProps;
};

/**
 * GELiveBlog
 * Description: [GE 24LiveBlog widget]
 * @param props - The props required by the component (if any)
 * @returns The rendered React element
 */
export default function GELiveBlog({
  variant,
  geLiveBlogContent,
  styles,
}: GELiveBlogProps): ReactElement {
  const wrapperStyle = styles?.wrapperStyle
    ? styles.wrapperStyle
    : "w-[300px] h-full";

  return (
    <div
      className={`${variant == "sidebar" ? wrapperStyle : "h-[500px]"} flex flex-col border-red-100 border-2`}
    >
      <div className="text-white-100 text-[15px] font-semibold bg-red-100 p-xs">
        {`Blog BH`}
      </div>
      <div
        className="h-full overflow-scroll"
        dangerouslySetInnerHTML={{ __html: geLiveBlogContent }}
      />
    </div>
  );
}
