import { BH_BASE_URL } from "@helper/getEnvVariables";
import { ReactElement } from "react";

const images = {
  youtube: `${BH_BASE_URL}assets/newsletter/youtube.png`,
  twitter: `${BH_BASE_URL}assets/newsletter/twitter.png`,
  instagram: `${BH_BASE_URL}assets/newsletter/instagram.png`,
  facebook: `${BH_BASE_URL}assets/newsletter/facebook.png`,
  logo: `${BH_BASE_URL}assets/newsletter/logo.png`,
};

export default function NewsletterHeader(): ReactElement {
  return (
    <table
      role="presentation"
      width="100%"
      cellSpacing={0}
      cellPadding={0}
      border={0}
      style={{ borderBottom: "1px solid #01519c", paddingBottom: "10px" }}
    >
      <tbody>
        <tr>
          <td align="left" style={{ padding: "10px 0" }}>
            <a
              href="https://www.beritaharian.sg"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#005aab", textDecoration: "none" }}
            >
              <img
                alt="Logo"
                width="350"
                src={images.logo}
                style={{
                  border: 0,
                  height: "auto",
                  lineHeight: "100%",
                  outline: "none",
                }}
              />
            </a>
          </td>
          <td
            align="right"
            style={{ padding: "10px 0", verticalAlign: "bottom" }}
          >
            <table
              role="presentation"
              cellSpacing={0}
              cellPadding={0}
              border={0}
            >
              <tbody>
                <tr>
                  {[
                    {
                      href: "http://www.youtube.com/user/BeritaHarianSG",
                      src: images.youtube,
                      alt: "YouTube",
                    },
                    {
                      href: "http://www.twitter.com/BeritaHarianSG/",
                      src: images.twitter,
                      alt: "Twitter",
                    },
                    {
                      href: "http://www.instagram.com/BeritaHarianSG/",
                      src: images.instagram,
                      alt: "Instagram",
                    },
                    {
                      href: "http://www.facebook.com/BeritaHarianSG/",
                      src: images.facebook,
                      alt: "Facebook",
                    },
                  ].map((item, index) => (
                    <td key={index} style={{ padding: "0 5px" }}>
                      <a
                        href={item.href}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: "#005aab", textDecoration: "none" }}
                      >
                        <img
                          src={item.src}
                          alt={item.alt}
                          width="40"
                          style={{
                            border: 0,
                            height: "auto",
                            lineHeight: "100%",
                            outline: "none",
                          }}
                        />
                      </a>
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
}
